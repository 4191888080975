body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Error page */
.ag-format-container {
  width: 1142px;
  margin: 0 auto;
}

.js-ag-hide {
  display: none;
}

.ag-page-404 {
  padding: 100px 0 0;
  background: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/404-error-smoke-from-toaster/images/toaster-bg.png)
    no-repeat 50% 100%;
  text-align: center;
}

.ag-page-404 h1 {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  transform: translateY(50px);
}

.ag-page-404 .subheading {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  transform: translateY(120px);
}

.ag-canvas-404_img {
  display: none;
}
.ag-canvas-404 {
  opacity: 0.7;
  filter: alpha(opacity=70);

  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
}

.ag-toaster-wrap {
  display: table;
  margin: 0 auto;
  padding: 200px 0 0;

  position: relative;
}
.ag-toaster {
  width: 340px;
  height: 217px;

  position: relative;
}
.ag-toaster_toast-handler {
  width: 155px;
  height: 200px;
  margin-left: 85px;
  padding-top: 60px;

  overflow: hidden;

  z-index: 1;
  position: absolute;
  top: -150px;
}
.ag-toaster_shadow {
  width: 165px;
  height: 9px;
  background: url(https://firebasestorage.googleapis.com/v0/b/deals-7301a.appspot.com/o/sprite-toaster.png?alt=media&token=2478b419-492a-4780-87d0-d99b9416f6cd) -340px -195px
    no-repeat;

  position: absolute;
  left: 0;
  bottom: 91px;
}
.ag-toaster_toast {
  width: 159px;
  height: 171px;
  background: url(https://firebasestorage.googleapis.com/v0/b/deals-7301a.appspot.com/o/sprite-toaster.png?alt=media&token=2478b419-492a-4780-87d0-d99b9416f6cd) -340px
    0 no-repeat;

  z-index: -1;
  position: relative;
}
.ag-toaster_front {
  width: 340px;
  height: 217px;
  background: url(https://firebasestorage.googleapis.com/v0/b/deals-7301a.appspot.com/o/sprite-toaster.png?alt=media&token=2478b419-492a-4780-87d0-d99b9416f6cd)
    0 -18px no-repeat;

  z-index: 2;
  position: relative;
}
.ag-toaster_lever {
  width: 22px;
  height: 21px;
  background: url(https://firebasestorage.googleapis.com/v0/b/deals-7301a.appspot.com/o/sprite-toaster.png?alt=media&token=2478b419-492a-4780-87d0-d99b9416f6cd) -340px -171px
    no-repeat;

  position: absolute;
  top: 75px;
  right: 12px;
}
.ag-toaster_back {
  width: 340px;
  height: 18px;
  background: url(https://firebasestorage.googleapis.com/v0/b/deals-7301a.appspot.com/o/sprite-toaster.png?alt=media&token=2478b419-492a-4780-87d0-d99b9416f6cd)
    no-repeat;

  z-index: -3;
  position: relative;
}

@keyframes handle-bounce-in-up {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  95% {
    -webkit-transform: translateY(-42px);
    -moz-transform: translateY(-42px);
    -ms-transform: translateY(-42px);
    -o-transform: translateY(-42px);
    transform: translateY(-42px);
  }
  100% {
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@keyframes toast-bounce-in-up {
  0% {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  80% {
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    -o-transform: translateY(5px);
    transform: translateY(5px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

.js-ag-animated {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}
.handle-bounce-up {
  animation-duration: 0.2s;
  animation-delay: 0.1s;
  animation-name: handle-bounce-in-up;
}

.toast-bounce-up {
  animation-duration: 0.4s;
  animation-name: toast-bounce-in-up;
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }
}

@media only screen and (max-width: 639px) {
}

@media only screen and (max-width: 479px) {
}

@media (min-width: 768px) and (max-width: 979px) {
  .ag-format-container {
    width: 750px;
  }
}

@media (min-width: 980px) and (max-width: 1161px) {
  .ag-format-container {
    width: 960px;
  }
}

.loader {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.loader.loader-visible {
  opacity: 1;
}
